import React from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles((theme) => ({
	root: {
		// marginLeft: '50px',
		marginTop: '80px',
		width: '100%',
		backgroundColor: 'white',
	},
	header: {
		backgroundColor: '#B3B2B2',
	},
	pos: {
		marginBottom: 3,
	},
	mailLink: {
		color: '#A91534'
	}
}))

function Kontakt() {
	const classes = useStyles()
	return (
		<Card className={classes.root} variant="outlined">
			<CardHeader className={classes.header} title='Holzschmiede Volkert' subheader="Steffen Volkert - Tischlermeister">
				<h1>Holzschmiede Volkert</h1>
			</CardHeader>
			<CardContent>
				<Typography className={classes.pos} color="textSecondary">
					29356 Bröckel
				</Typography>
				<Typography className={classes.pos} color="textSecondary">
					Genossenschaftsstraße 18
				</Typography>
				<Typography className={classes.pos} color="textSecondary">
					Mobil: 0176 31 38 40 00
				</Typography>
				<Typography className={classes.pos} color="textSecondary">
					<a
						className={classes.mailLink}
						href="mailto:info@holzschmiede-volkert.de"
					>
						info@holzschmiede-volkert.de
					</a>
				</Typography>
			</CardContent>
		</Card>
	)
}
export default Kontakt
