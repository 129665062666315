import React, {useContext} from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ContentWrapper from '../components/ContentWrapper';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		position: 'relative',
		marginLeft: '100px',
		marginRight: '100px',
		border: '1px solid',
		backgroundColor: 'white',
		borderRadius: '3px',
		width: '100%'
	},
}))

const ContentContext = React.createContext()

export function useContentContext() {
	return useContext(ContentContext)
}

function ContentProvider({children}) {
	const classes = useStyles()
	return (
			<ContentContext.Provider value={null}>
				<div className={classes.root}>
					{children}
				</div>
			</ContentContext.Provider>
	)

}

export default ContentProvider
