import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import LandingPage from '../pages/LandingPage';
import HS_BACKGROUND from '../assets/background-image.jpg'
import ContentProvider from '../pages/ContentProvider';
import {BrowserRouter, Route} from 'react-router-dom';
import Impressum from '../pages/Impressum';
import Kontakt from '../pages/Kontakt';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		position: 'absolute',
		// marginTop: '70px',
		height: '100%',
		width: "100%",
		backgroundImage: `url(${HS_BACKGROUND})`
	}
}))

function ContentWrapper() {
	const classes = useStyles()

	return (
		<div className={classes.root}>
		</div>
	)

}

export default ContentWrapper
