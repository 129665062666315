import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import ContentWrapper from './components/ContentWrapper';
import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Impressum from './pages/Impressum';
import Kontakt from './pages/Kontakt';
import ContentProvider from './pages/ContentProvider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HS_BACKGROUND from './assets/background-image.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        position: 'absolute',
        // marginTop: '70px',
        height: '100%',
        width: "100%",
        backgroundImage: `url(${HS_BACKGROUND})`
    }
}))

function App() {
    const classes = useStyles()
  return (
    <divs className={classes.root}>
        <BrowserRouter>
            <Header/>
            <ContentProvider>
                <Route exact path="/" component={LandingPage}/>
                <Route exact path="/impressum" component={Impressum}/>
                <Route exact path="/kontakt" component={Kontakt}/>
            </ContentProvider>
            <Footer/>
        </BrowserRouter>
    </divs>
  );
}

export default App;
