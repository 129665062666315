import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
	root: {
		// marginLeft: '50px',
		marginTop: '80px',
		width: '100%',
		backgroundColor: 'white',
	},
}))

function LandingPage() {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			Hier entsteht der Webauftritt der Holzschmiede-Volkert
		</div>
	)

}

export default LandingPage
