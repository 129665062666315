import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import HS_LOGO from '../assets/HolzSchmiede-Logo-RZ.png'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		position: 'fixed',
		left: '0',
		right: '0',
		zIndex: '1',
		backgroundColor: 'white',
		boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)',
		height: '70px',
		width: "100%",
	},
	logo: {
		height: '60px',
		paddingTop: '3px',
		paddingRight: '30px',
		cursor: 'pointer',
		marginLeft: '50px',
	},
	headerText: {
		textAlign: 'center',
		marginBlock: 'auto',
	},
	headerLink: {
		color: '#A91534',
		textDecoration: 'none',
	}
}))

function Header() {
	const classes = useStyles()
	const history = useHistory()

	return (
		<div className={classes.root}>
			<img alt="Logo"
				 className={classes.logo}
				 onClick={() => {history.push('/')}}
				 src={HS_LOGO}
			/>
				<div className={classes.headerText}>
					<a
						className={classes.headerLink}
						href="tel:0176 31 38 40 00"
					>0176 31 38 40 00
					</a>
				</div>
		</div>
	)

}

export default Header
