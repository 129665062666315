import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Impressum from '../pages/Impressum';
import {Link, useHistory} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		position:'absolute',
		bottom: '0',
		height: '100px',
		width: "100%",
		// marginBottom: '-70px',
		boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)',
		backgroundColor: '#D3BC9C'
	}, links: {
		textAlign: 'center',
		padding: '20px',
		textDecoration: 'none',
		color: '#A91534'
	}, linkWrapper: {
		margin: 'auto'
	}
}))

function Footer() {
	const classes = useStyles()
	const history = useHistory()

	return (
		<div className={classes.root}>
			<div className={classes.linkWrapper}>
				<Link
					className={classes.links}
					to='/impressum'>Impressum
				</Link>
				-
				<Link className={classes.links}
					  to='/kontakt'>Kontakt
				</Link>
			</div>
		</div>
	)

}

export default Footer
