import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';


const useStyles = makeStyles((theme) => ({
	root: {
		// marginLeft: '50px',
		marginTop: '80px',
		width: '100%',
		backgroundColor: 'white',
	},
	header: {
		backgroundColor: '#B3B2B2',
	},
	pos: {
		marginBottom: 3,
	},
	mailLink: {
		color: '#A91534'
	}
}))

function Impressum() {
	const classes = useStyles();
	return (
		<Card className={classes.root} variant="outlined">
			<CardHeader className={classes.header} title='Impressum' subheader="Gesetzliche Anbieterkennung">
			</CardHeader>
			<CardContent>
				<Typography className={classes.pos} color="textSecondary">
					Holzschmiede Volkert
				</Typography>
				<Typography className={classes.pos} color="textSecondary">
					29356 Bröckel<br/>
					Genossenschaftsstraße 18<br/>
					Mobil: 0176 31 38 40 00<br/>
					Geschäftsführer: Steffen Volkert<br/>
					Gerichtsstand und Erfüllungsort: Celle<br/>
					Steuer-Nr.: 17/145/04465<br/>
					USt ID-Nr.: DE340371688<br/>
					<br/>
					Stand 13.08.2021
				</Typography>
				<Typography className={classes.pos} color="textSecondary">
					<a
						className={classes.mailLink}
						href="mailto:info@holzschmiede-volkert.de"
					>
						info@holzschmiede-volkert.de
					</a>
				</Typography>
			</CardContent>
		</Card>
	)

}

export default Impressum
